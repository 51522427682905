//=require ./swiper.min.js
//=require ./vue.min.js
//=require ./jcf.js
//=require ./jcf.scrollable.js
//=require ./jcf.select.js


(function ($) {
    var games = document.getElementById("games");
    var perPage = 20;
    var tagValue = '0';
    var countLastLoadedGames = 0;



    if(games) {
        $.holdReady(true)
    }

    function getGames() {
        var data = { action : 'get_post_by_tag', tag: tagValue, perPage: perPage };
        $.ajax({
            type: 'POST',
            url: '/wp-admin/admin-ajax.php',
            dataType: 'json',
            data: data,
            success: function(response){
                console.log(response);
                app.loaded = true;
                app.games = response;
                if (response.length > perPage && response.length % 20 !== 0) {
                    app.showMore = false;
                }
                if(countLastLoadedGames === response.length) {
                    app.showMore = false;
                } else {
                    countLastLoadedGames = response.length;
                }

            }
        });
    }

    document.addEventListener("DOMContentLoaded", () => {

        if(games) {
            window.app = new Vue({
                el: '#games',
                mounted() {
                    $.holdReady(false)
                },
                data: {
                    showMore: true,
                    loaded: false,
                    allProviders: true,
                    games: []
                },
                methods: {
                    loadMore: function (e) {
                        e.preventDefault();
                        perPage += 20;
                        getGames();
                    }
                }
            })
        } else {
            $.holdReady(false)
        }

    })

    $(document).ready(function () {
        var swiper = new Swiper('.swiper-container');

        // games
        getGames();

        $("#game-providers").change(function () {
            var that = this;
            tagValue = that.value;
            perPage = 20;
            app.showMore = true;
            countLastLoadedGames = 0

            getGames();
        })

        $('.burger').on('click', function () {
            $('.header__nav-left, .menu-all-pages-container').toggleClass('active');
        })

        jcf.replaceAll();

        $('.js-nav-tab__new').on('click', function (e) {
            e.preventDefault();
            $('.js-nav-tab__new').removeClass('nav-tabs__link_active');
            $(this).addClass('nav-tabs__link_active');
            var target=$(this).data('target');
            $('.js-nav-content').removeClass('active');
            $('[data-content="'+ target +'"]').addClass('active');
        })
    })


}(jQuery));
